import React, { useState, useEffect, useContext } from 'react';

import ModalHeader from '../ModalHeader';
import ModalFooter from '../ModalFooter';
import FetchApi from '../../REST';
import { GettextContext } from 'rootReact/context';
import FaIcon from 'rootReact/components/FaIcon';
import loader from '../../../../images/loading.svg';
import { usePlural } from '../../hook/pluralReact';

function UserModalPolicy({ onModalClose }) {
    const gettext = useContext(GettextContext);
    const [loading, setLoading] = useState(true);
    const [formError, setFormError] = useState('');
    const [save, setSave] = useState(false);
    const [saveDone, setSaveDone] = useState(false);
    const [checkboxUpperCase, setCheckboxUpperCase] = useState(false);
    const [checkboxLowerCase, setCheckboxLowerCase] = useState(false);
    const [checkboxNumbers, setCheckboxNumbers] = useState(false);
    const [checkboxSymbols, setCheckboxSymbols] = useState(false);
    const [inputLengthPass, setInputLengthPass] = useState(0);
    const [inputCountNumbers, setInputCountNumbers] = useState(0);
    const [inputLastPass, setInputLastPass] = useState(0);
    const [inputInvalidPass, setInputInvalidPass] = useState(0);
    const [minPasswordVal, setMinPasswordVal] = useState(0);
    const [globalValue, setGlobalValue] = useState({
        char_big: 0,
        char_small: 0,
        char_special: 0,
        digit: 0,
        digit_min: 0,
        length: 0,
        password_active_days: 0,
        password_verify_count: 0,
    });

    useEffect(() => {
        try {
            fetchData();
        } catch (e) {
            setFormError(e.err.error || gettext.getString('Что-то пошло не так :-('));
        }
        async function fetchData() {
            setFormError('');
            const response = await FetchApi('/settings/policy').get();
            if (response && response.isError) {
                setFormError(gettext.getString('Ошибка при загрузке требований к паролю'));
                throw response;
            }
            setCheckboxUpperCase(response.char_big);
            setCheckboxLowerCase(response.char_small);
            setCheckboxSymbols(response.char_special);
            setCheckboxNumbers(response.digit);
            setInputCountNumbers(response.digit_min);
            setInputLengthPass(response.length);
            setInputInvalidPass(response.password_active_days);
            setInputLastPass(response.password_verify_count);
            setMinPasswordVal(response.char_big + response.char_small + response.char_special + response.digit_min);
            setGlobalValue({
                ...globalValue,
                char_big: response.global.char_big,
                char_small: response.global.char_small,
                char_special: response.global.char_special,
                digit: response.global.digit,
                digit_min: response.global.digit_min,
                length: response.global.length,
                password_active_days: response.global.password_active_days,
                password_verify_count: response.global.password_verify_count,
            });
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if (!checkboxUpperCase && inputCountNumbers === 0 && !checkboxLowerCase && !checkboxSymbols) {
            setMinPasswordVal(0);
            setInputLengthPass(0);
        }
    }, [checkboxUpperCase, inputCountNumbers, checkboxLowerCase, checkboxSymbols]);

    useEffect(() => {
        if (minPasswordVal > inputLengthPass) {
            setInputLengthPass(minPasswordVal);
        }
    }, [minPasswordVal, inputLengthPass]);

    const computeValuePasswordLength = (checkboxValue) => {
        if (!checkboxValue) {
            setMinPasswordVal(prev => prev + 1);
        } else {
            setMinPasswordVal(prev => prev - 1);
        }
    };

    const inputClickNumbers = () => {
        if (checkboxNumbers) {
            setMinPasswordVal(prev => prev - inputCountNumbers - 1);
            setInputCountNumbers(0);
        } else {
            setMinPasswordVal(prev => prev + 2);
            setInputCountNumbers(prev => prev + 1);
        }
    };

    const changeCountNumbers = (number) => {
        setInputCountNumbers(number);
        setMinPasswordVal(prev => prev + (number - inputCountNumbers));
    };

    const changeInputLengthPassValue = (e) => {
        if (checkboxUpperCase || checkboxLowerCase || checkboxSymbols || inputCountNumbers > 0) {
            setInputLengthPass(Number(e.target.value));
        }
    };

    const onSavePolicy = async () => {
        try {
            setSave(true);
            setFormError('');
            const response = await FetchApi('/settings/policy').post({
                body: {
                    char_big: checkboxUpperCase,
                    char_small: checkboxLowerCase,
                    char_special: checkboxSymbols,
                    digit: checkboxNumbers,
                    digit_min: inputCountNumbers,
                    length: inputLengthPass,
                    password_active_days: inputInvalidPass,
                    password_verify_count: inputLastPass,
                },
            });
            if (response && response.isError) {
                throw response;
            }
            setSave(false);
            setSaveDone(true);
            onModalClose(response);
        } catch (e) {
            setSave(false);
            setSaveDone(false);
            setFormError(e.err || gettext.getString('Ошибка при сохранении требований к паролю'));
        }
    };

    return (
        <>
            <ModalHeader title={gettext.getString('Требования к паролю')} closeModal={() => onModalClose()} />
            {loading ? (
                <img
                    style={{
                        width: '40px',
                        height: '40px',
                        display: 'inline',
                        marginLeft: '48%',
                        marginTop: '5%',
                        marginBottom: '5%',
                    }}
                    src={loader}
                    alt=''
                />
            ) : (
                <>
                    <div className='react-modal-policy'>
                        <div
                            className={
                                (checkboxUpperCase || checkboxLowerCase || checkboxNumbers || checkboxSymbols) &&
                                inputInvalidPass > 0
                                    ? 'warning-none'
                                    : 'react-modal-policy_warning'
                            }
                        >
                            <span
                                className={
                                    checkboxUpperCase || checkboxLowerCase || checkboxNumbers || checkboxSymbols
                                        ? 'str1'
                                        : ''
                                }
                            >
                                {gettext.getString('Требования к сложности пароля отключены.')}&nbsp;
                            </span>
                            <span
                                className={
                                    checkboxUpperCase || checkboxLowerCase || checkboxNumbers || checkboxSymbols
                                        ? 'str2'
                                        : ''
                                }
                            >
                                {gettext.getString(
                                    'Для включения: выберите символы, которые обязательно должны присутствовать в пароле.'
                                )}
                                &nbsp;
                            </span>
                            <span className={inputInvalidPass > 0 ? 'str3' : ''}>
                                {gettext.getString('Устаревание паролей отключено.')}
                            </span>
                        </div>
                        <div className='react-modal-policy_block'>
                            <label
                                htmlFor='length'
                                className={inputLengthPass < globalValue.length ? 'text-error' : ''}
                            >
                                {gettext.getString('Минимальная длина:')}&nbsp;
                            </label>
                            <input
                                value={inputLengthPass}
                                onChange={changeInputLengthPassValue}
                                className='react-modal-policy_length'
                                type='number'
                                id='length'
                                min={minPasswordVal}
                            />
                            <span className={inputLengthPass < globalValue.length ? 'text-error' : ''}>
                                &nbsp;
                                {usePlural(inputLengthPass, [
                                    gettext.getString('символ, включая:'),
                                    gettext.getString('символа, включая:'),
                                    gettext.getString('символов, включая:'),
                                ])}
                            </span>
                        </div>
                        <div className='react-modal-policy_block'>
                            <input
                                onClick={() => computeValuePasswordLength(checkboxUpperCase)}
                                checked={checkboxUpperCase}
                                onChange={() => setCheckboxUpperCase((prev) => !prev)}
                                className='modal-policy_checkox-input'
                                type='checkbox'
                                id='checkbox1'
                            />
                            <label
                                htmlFor='checkbox1'
                                className={
                                    checkboxUpperCase < globalValue.char_big
                                        ? 'modal-policy_checkbox-label text-error'
                                        : 'modal-policy_checkbox-label'
                                }
                            >
                                {gettext.getString('A-Z: заглавные буквы')}
                            </label>
                        </div>
                        <div className='react-modal-policy_block'>
                            <input
                                onClick={() => computeValuePasswordLength(checkboxLowerCase)}
                                checked={checkboxLowerCase}
                                onChange={() => setCheckboxLowerCase((prev) => !prev)}
                                className='modal-policy_checkox-input'
                                type='checkbox'
                                id='checkbox2'
                            />
                            <label
                                htmlFor='checkbox2'
                                className={
                                    checkboxLowerCase < globalValue.char_small
                                        ? 'modal-policy_checkbox-label text-error'
                                        : 'modal-policy_checkbox-label'
                                }
                            >
                                {gettext.getString('a-z: строчные буквы')}
                            </label>
                        </div>
                        <div className='react-modal-policy_block'>
                            <input
                                onClick={inputClickNumbers}
                                checked={checkboxNumbers}
                                onChange={() => setCheckboxNumbers((prev) => !prev)}
                                className='modal-policy_checkox-input'
                                type='checkbox'
                                id='checkbox3'
                            />
                            <label
                                className={
                                    checkboxNumbers < globalValue.digit
                                        ? 'modal-policy_checkbox-label text-error'
                                        : 'modal-policy_checkbox-label'
                                }
                            >
                                {gettext.getString('0-9: цифры, не менее')}&nbsp;
                            </label>
                            <input
                                value={inputCountNumbers}
                                onChange={(e) => changeCountNumbers(Number(e.target.value))}
                                className={
                                    inputCountNumbers < globalValue.digit_min
                                        ? 'react-modal-policy_length input-error'
                                        : 'react-modal-policy_length'
                                }
                                type='number'
                                min='0'
                                step='1'
                                disabled={checkboxNumbers ? false : true}
                            />
                            <span className={inputCountNumbers < globalValue.digit_min ? 'text-error' : ''}>
                                &nbsp;
                                {usePlural(inputCountNumbers, [
                                    gettext.getString('символ'),
                                    gettext.getString('символа'),
                                    gettext.getString('символов'),
                                ])}
                            </span>
                        </div>
                        <div className='react-modal-policy_block'>
                            <input
                                onClick={() => computeValuePasswordLength(checkboxSymbols)}
                                checked={checkboxSymbols}
                                onChange={() => setCheckboxSymbols((prev) => !prev)}
                                className='modal-policy_checkox-input'
                                type='checkbox'
                                id='checkbox4'
                            />
                            <label
                                htmlFor='checkbox4'
                                className={
                                    checkboxSymbols < globalValue.char_special
                                        ? 'modal-policy_checkbox-label text-error'
                                        : 'modal-policy_checkbox-label'
                                }
                            >
                                {gettext.getString('!@#: спецсимволы')}
                            </label>
                        </div>
                        <div className='react-modal-policy_block'>
                            <label className={inputLastPass < globalValue.password_verify_count ? 'text-error' : ''}>
                                {gettext.getString('Запретить повторно использовать:')}&nbsp;
                            </label>
                            <input
                                value={inputLastPass}
                                onChange={(e) => setInputLastPass(Number(e.target.value))}
                                className={
                                    inputLastPass < globalValue.password_verify_count
                                        ? 'react-modal-policy_length input-error'
                                        : 'react-modal-policy_length'
                                }
                                type='number'
                                min='0'
                            />
                            <span className={inputLastPass < globalValue.password_verify_count ? 'text-error' : ''}>
                                &nbsp;
                                {usePlural(inputLastPass, [
                                    gettext.getString('последний пароль'),
                                    gettext.getString('последних пароля'),
                                    gettext.getString('последних паролей'),
                                ])}
                            </span>
                        </div>
                        <div className='react-modal-policy_block'>
                            <label className={inputInvalidPass < globalValue.password_active_days ? 'text-error' : ''}>
                                {gettext.getString('Срок действия пароля:')}&nbsp;
                            </label>
                            <input
                                value={inputInvalidPass}
                                onChange={(e) => setInputInvalidPass(Number(e.target.value))}
                                className='react-modal-policy_length'
                                type='number'
                                min='0'
                            />
                            <span className={inputInvalidPass < globalValue.password_active_days ? 'text-error' : ''}>
                                &nbsp;
                                {usePlural(inputInvalidPass, [
                                    gettext.getString('день'),
                                    gettext.getString('дня'),
                                    gettext.getString('дней'),
                                ])}
                            </span>
                        </div>
                    </div>
                </>
            )}
            <div className='react-modal-policy_error'>
                <ModalFooter
                    titleBtnOk={gettext.getString('Сохранить')}
                    titleBtnClose={gettext.getString('Отменить')}
                    closeModal={() => onModalClose()}
                    onEnter={onSavePolicy}
                    disabled={
                        loading ||
                        inputLastPass < globalValue.password_verify_count ||
                        inputInvalidPass < globalValue.password_active_days ||
                        checkboxSymbols < globalValue.char_special ||
                        inputCountNumbers < globalValue.digit_min ||
                        checkboxNumbers < globalValue.digit ||
                        checkboxLowerCase < globalValue.char_small ||
                        checkboxUpperCase < globalValue.char_big ||
                        inputLengthPass < globalValue.length
                            ? true
                            : false
                    }
                />
                {formError.length !== 0 ? <FaIcon className='react-icon-error' type={'error'} text={formError} /> : ''}
                {save ? <FaIcon className='react-icon-error' type={'pending'} text={gettext.getString('Сохранение...')} /> : ''}
                {saveDone ? <FaIcon className='react-icon-error' type={'success'} text={gettext.getString('Изменения сохранены')} /> : ''}
            </div>
        </>
    );
}

export default UserModalPolicy;
